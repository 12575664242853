
































import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CookiesConsentModal',
  data() {
    return {
      // eslint-disable-next-line no-underscore-dangle
      isAccessingFromStoryblok: !!this.$route.query._storyblok,
      cookies: (this as any).$cookiesNuxtControl as any,
    };
  },
  mounted() {
    if (this.cookies) {
      if (this.cookies.consent) {
        return;
      }

      if (this.isAccessingFromStoryblok) {
        this.cookies.modal = false;
        return;
      }
      this.cookies.modal = true;
    }
    this.updateModalStyling();
  },
  updated() {
    this.updateModalStyling();
  },
  methods: {
    updateModalStyling() {
      // get .cookieControl__ModalClose and if exists, change innerHTML to X
      if (document.querySelectorAll('.cookieControl__ModalClose').length > 0) document.querySelectorAll('.cookieControl__ModalClose')[0].innerHTML = 'X';

      // Remove uneccessary texts
      document.querySelectorAll('h3').forEach((h3) => h3.remove());

      // Take both radio buttons and align them
      const radioButtonsContent = document.createElement('div');
      radioButtonsContent.classList.add('cookieControl__RadioContent');
      radioButtonsContent.style.justifyContent = 'center';
      document.querySelectorAll('.cookieControl__ModalContent > div > ul').forEach((ul) => radioButtonsContent.append(ul));

      // Add margin top and flex to radioButtonsContent
      radioButtonsContent.style.marginTop = '2rem';
      radioButtonsContent.style.display = 'flex';

      document.querySelectorAll('p')[0].insertAdjacentElement('afterend', radioButtonsContent);

      // Get label with text ANALYSE / STATISTIKEN and add class cookieControl__secondButton
      const cookieControlSecondButton = radioButtonsContent.querySelectorAll('label')[1];
      if (cookieControlSecondButton) {
        cookieControlSecondButton.classList.add('cookieControl__secondButton');
      }

      const cookieModalButtonsContent = document.querySelectorAll('.cookieControl__ModalButtons')[0] as HTMLElement;
      if (cookieModalButtonsContent && cookieModalButtonsContent.querySelectorAll('button').length > 0) {
          cookieModalButtonsContent.querySelectorAll('button').forEach((button: HTMLElement) => {
            if (button.innerHTML === 'Speichern') {
              button.classList.add('cookieControl__button-secondary--default');
              button.classList.add('!rounded');
              button.classList.add('order-2');
              button.classList.add('lg:order-1');
              button.classList.add('ml-5');
            }
            if (button.innerHTML === 'Alle akzeptieren') {
              button.classList.add('cookieControl__button-primary--default');
              button.classList.add('!rounded');
              button.classList.add('order-1');
              button.classList.add('lg:order-2');
            }
            if (button.innerHTML === 'Alle ablehnen') {
              button.remove();
            }
          });
        }

      const cookieControlNames = document.querySelectorAll('cookieControl__ModalCookieName');
      if (cookieControlNames.length > 0) {
        cookieControlNames[0].innerHTML = 'NOTWENDIG';
        cookieControlNames[1].innerHTML = 'ANALYSE / STATISTIKEN';
      }
    },
  },
});
